import React from 'react';

import CloseIcon from '@assets/browse-books/icon-close.svg';

export default function SubscribeTools(props) {
  const { status, message, onValidated, signUp, placeHolderText } = props;
  const MAIL_CHIMP_URL =
    'https://gmail.us20.list-manage.com/subscribe/post?u=8c56121f995f86c98152c571c&amp;id=7d7595a6a6';

  const [email, setEmail] = React.useState('');
  const [show, setShow] = React.useState('true');
  const [showSubscriptionNotification, setShowSubscriptionNotification] = React.useState(false);
  React.useEffect(() => {
    if (status === 'success') {
      setEmail('');
    }
  }, [status]);

  const handleSubmit = (e) => {
    e.preventDefault();
    email && email.indexOf('@') > -1 && onValidated({ EMAIL: email });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="subscribe-box ">
          <div className="subscribe-input-field">
            <input
              type="email"
              name="subscription"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={placeHolderText}
              required
            />
          </div>
          <div className="subscribe-text">
            <button
              className="sign-up-subcribe-text"
              type="submit"
              disabled={!email}
              onClick={() => {
                setShowSubscriptionNotification(true);
                setTimeout(() => {
                  setShowSubscriptionNotification(false);
                }, 5000);
              }}
            >
              {signUp}
            </button>
          </div>
        </div>
      </form>
      <div className={showSubscriptionNotification ? 'subscription-notification-container' : 'hide'}>
        <h6>
          {status === 'success'
            ? 'Thank you for subscribing!'
            : 'Something went wrong! you might have subscribed already.'}
        </h6>
        <span onClick={() => setShowSubscriptionNotification(false)}>
          <img src={CloseIcon} />
        </span>
      </div>
    </div>
  );
}
