import React from 'react';

export default function Visa() {
  return (
    <svg width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2258:3432)">
        <path d="M37.3415 0.341553H0.177429V23.3009H37.3415V0.341553Z" fill="white" />
        <path d="M36.7426 19.5369H0.810486V22.6848H36.7426V19.5369Z" fill="#FCB316" />
        <path d="M36.7426 0.922485H0.810486V4.07041H36.7426V0.922485Z" fill="#10357F" />
        <path
          d="M15.1667 7.49295L11.4714 16.1155H9.04314L7.21311 9.238C7.10753 8.82742 7.00195 8.65634 6.68521 8.48523C6.12213 8.21149 5.2423 7.93776 4.43286 7.76668L4.50325 7.52716H8.40967C8.90241 7.52716 9.35991 7.83511 9.46545 8.41679L10.4157 13.4125L12.8088 7.56138H15.1667V7.49295ZM24.6689 13.3098C24.6689 11.0515 21.4311 10.9146 21.4663 9.88814C21.4663 9.58017 21.783 9.238 22.4517 9.1696C22.7684 9.13531 23.6834 9.10111 24.704 9.54597L25.0912 7.73246C24.528 7.52716 23.8242 7.35608 22.9444 7.35608C20.692 7.35608 19.0731 8.51944 19.0731 10.196C19.0731 11.4278 20.1993 12.1122 21.0791 12.5227C21.9589 12.9334 22.2757 13.2071 22.2757 13.5835C22.2757 14.1652 21.5718 14.4047 20.9032 14.4389C19.7418 14.4732 19.0731 14.131 18.5452 13.8914L18.1229 15.7734C18.6508 16.0129 19.6363 16.2181 20.6568 16.2181C23.0851 16.2524 24.6337 15.089 24.6689 13.3098ZM30.6516 16.1155H32.7632L30.898 7.49295H28.9272C28.5049 7.49295 28.1177 7.73246 27.9418 8.10885L24.4928 16.0813H26.886L27.3787 14.7811H30.2997L30.6516 16.1155ZM28.0826 13.0703L29.2791 9.85385L29.983 13.0703H28.0826ZM18.4397 7.49295L16.5392 16.1155H14.2517L16.1521 7.49295H18.4397Z"
          fill="#10357F"
        />
      </g>
      <defs>
        <clipPath id="clip0_2258:3432">
          <rect width="37.5652" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
