import React, { useState, useEffect, useContext } from 'react';
import SubscribeBlog from '../blog/subscribe';
import FooterBody from './footer-body';
import { Link } from 'gatsby';
import LanguageContext from '../../context/language-context';

function Footer() {
  let dateNow = new Date();
  let currentYear = dateNow.getFullYear();

  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      privacyPolicy: 'Privacy policy',
      termsAndCondition: 'Terms and conditions',
    },
    esp: {
      privacyPolicy: 'Política de privacidad',
      termsAndCondition: 'Términos y condiciones',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);

  return (
    <div className="footer-container " id="footer-body">
      <SubscribeBlog />
      <FooterBody />
      <div className="line-footer"></div>
      <div className="common-container">
        {' '}
        <div className="footer">
          <div className="copyright">
            <p className="copyright-text">©{currentYear} Better Way of Life </p>
          </div>
          <div className="terms-policy">
            <div className="policy">
              <Link to="/privacy-policy">
                <p className="policy-text">{currentContent?.privacyPolicy}</p>
              </Link>
            </div>
            <div className="terms">
              <Link to="/terms-and-conditions">
                <p className="terms-text">{currentContent?.termsAndCondition}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
