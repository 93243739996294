import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';

import Logo from '@assets/svg/logo';
import Mastercard from '@assets/footer/mastercard';
import Visa from '@assets/footer/visa';
import AmericanExpress from '@assets/footer/american-express';
import Paypal from '@assets/footer/paypal';
import Dhl from '@assets/footer/dhl';
import fedex from '@assets/footer/png/fedex.png';
import ups from '@assets/footer/png/ups.png';
import LanguageContext from '../../context/language-context';

const FooterBody = () => {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      quickLinks: 'Quick Links',
      browseBooks: 'Browse Books',
      about: 'About',
      contact: 'Contact',
      blogs: 'Blogs',
      faqs: 'FAQS',
      social: 'Social',
      paymentPartners: 'Payment partners',
      deliveryPartners: 'Delivery partners',
      footerBodyTitle: 'Be better than you were yesterday',
    },
    esp: {
      quickLinks: 'Enlaces rápidos',
      browseBooks: 'Explorar libros',
      about: 'Sobre',
      contact: 'Contacto',
      blogs: 'Blogs',
      faqs: 'FAQS',
      social: 'Social',
      paymentPartners: 'Socios de pago',
      deliveryPartners: 'Socios de entrega',
      footerBodyTitle: 'Ser mejor que tu fueron ayer',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="outermost-footer-holder">
      <div className="common-container">
        <div className="footer-body-holder">
          <div className="footer-body-content footer-body-content-corner">
            <Link to="/">
              <div className="footer-logo">
                <Logo />
              </div>
            </Link>
            <div className="footer-company-slogan">
              <h5>{currentContent?.footerBodyTitle}</h5>
            </div>
          </div>
          <div className="footer-body-content footer-body-grid">
            <h6 className="footer-grid-heading">{currentContent?.quickLinks}</h6>
            <Link to="/books">
              <h6 className="button-text-footer">{currentContent?.browseBooks}</h6>
            </Link>
            <Link to="/about-us">
              <h6 className="button-text-footer">{currentContent?.about}</h6>
            </Link>
            <Link to="/contact">
              <h6 className="button-text-footer">{currentContent?.contact}</h6>
            </Link>
            <Link to="/blog">
              <h6 className="button-text-footer">Blogs</h6>
            </Link>
            <Link to="/faqs">
              <h6 className="button-text-footer hide">FAQS</h6>
            </Link>
          </div>
          <div className="footer-body-content footer-body-grid">
            <h6 className="footer-grid-heading">{currentContent?.social}</h6>
            {/* <a href="https://www.facebook.com/"> */}
            <h6 className="button-text-footer">Facebook</h6>
            {/* </a> */}
            {/* <a href="https://www.instagram.com/"> */}
            <h6 className="button-text-footer">Instagram</h6>
            {/* </a> */}
            {/* <a href="https://www.linkedin.com/"> */}
            <h6 className="button-text-footer">Linkedin</h6>
            {/* </a> */}
            {/* <a href="https://www.twitter.com/"> */}
            <h6 className="button-text-footer">Twitter</h6>
            {/* </a> */}
          </div>
          <div className="footer-body-content footer-body-content-corner">
            <div className="footer-partners">
              <h6>{currentContent?.paymentPartners}</h6>
              <div className="footer-partners-card">
                <div className="partners-card-container">
                  <Visa />
                </div>
                <div className="partners-card-container">
                  <Mastercard />
                </div>
                <div className="partners-card-container">
                  <AmericanExpress />
                </div>

                <div className="partners-card-container margin-top-2rem">
                  <Paypal />
                </div>
              </div>
            </div>
            <div className="footer-partners">
              <h6>{currentContent?.deliveryPartners}</h6>
              <div className="footer-partners-card">
                <div className="partners-card-container-delivery">
                  <img src={ups} alt="delivery partners" />
                </div>
                <div className="partners-card-container-delivery">
                  <img src={fedex} alt="delivery partners" />
                </div>

                <div className="partners-card-container-delivery">
                  <Dhl />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBody;
