import React, { useState, useContext, useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import SubscribeTools from './subscribe-tool';
import SubscribeBack from '@assets/blog/images/Vector.png';
import LanguageContext from '../../context/language-context';
export default function SubscribeBlog() {
  const { contentLanguage } = useContext(LanguageContext);

  const MAIL_CHIMP_URL =
    'https://gmail.us20.list-manage.com/subscribe/post?u=8c56121f995f86c98152c571c&amp;id=7d7595a6a6';

  const contentResources = {
    eng: {
      titleFirstHalf: ' Want to get tips on the healthiest recipes',
      titleSecondHalf: ' along with your preferred books?',
      signUp: 'Sign Up',
      placeHolderText: 'Your Email Address',
    },
    esp: {
      titleFirstHalf: '¿Quieres recibir consejos sobre las recetas más',
      titleSecondHalf: 'saludables junto con tus libros preferidos?',
      signUp: 'Regístrate',
      placeHolderText: 'Su dirección de correo electrónico',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="footer-subscribe-outermost">
      <div className="subscribe-content-holder common-container">
        <div className="sign-up-request">
          <h3>
            {currentContent.titleFirstHalf}
            <br />
            {currentContent.titleSecondHalf}
          </h3>
        </div>
        <div className="subscribe-tool">
          <MailchimpSubscribe
            url={MAIL_CHIMP_URL}
            render={({ subscribe, status, message }) => (
              <SubscribeTools
                signUp={currentContent.signUp}
                placeHolderText={currentContent.placeHolderText}
                status={status}
                message={message}
                onValidated={(formData) => subscribe(formData)}
              />
            )}
          />
        </div>
        <div className="background-logo-holder">
          <div className="background-logo">
            <img src={SubscribeBack} alt="brand logo" />
          </div>
        </div>
      </div>
    </div>
  );
}
