import React from 'react';

export default function Dhl() {
  return (
    <svg width="78" height="18" viewBox="0 0 78 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M78 0.830078V17.4136H0V0.830078H78Z" fill="#FECC00" />
      <path
        d="M25.1489 7.75982C24.8081 8.20449 24.2378 8.97755 23.8907 9.42907C23.7145 9.65868 23.3963 10.0756 24.4512 10.0756H30.0056C30.0056 10.0756 30.9008 8.9023 31.6513 7.92101C32.6721 6.58612 31.7396 3.80859 28.0901 3.80859H13.7175L11.2255 7.07056H24.8076C25.4933 7.07056 25.484 7.32198 25.1489 7.75982ZM21.0685 10.8803C20.0135 10.8803 20.3317 10.4625 20.5079 10.2329C20.855 9.78139 21.4346 9.01646 21.7755 8.57178C22.111 8.13394 22.1199 7.88253 21.4333 7.88253H15.2216L10.2167 14.4355H22.4221C26.4533 14.4355 28.6977 11.794 29.3896 10.8807L21.0685 10.8803ZM29.0083 14.4355H36.1684L38.8828 10.8798L31.7236 10.8807C31.7214 10.8803 29.0083 14.4355 29.0083 14.4355ZM47.4822 3.80859L44.7359 7.40236H41.5404L44.2854 3.80859H37.1275L32.3387 10.0756H49.8518L54.6392 3.80859H47.4822ZM39.3639 14.4355H46.5214L49.2371 10.8807H42.0797C42.077 10.8803 39.3639 14.4355 39.3639 14.4355ZM0 12.2827V13.0378H9.97169L10.5478 12.2827H0ZM11.6196 10.8803H0V11.6345H11.0422L11.6196 10.8803ZM0 14.4355H8.90342L9.47683 13.6847H0V14.4355ZM67.4922 13.0374H78V12.2823H68.0691L67.4922 13.0374ZM66.4248 14.4355H78V13.6847H66.9978L66.4248 14.4355ZM69.1392 10.8803L68.5636 11.6354H78V10.8803H69.1392ZM59.764 10.0756L64.5523 3.80859H56.9715C56.9688 3.80859 52.1796 10.0756 52.1796 10.0756H59.764ZM51.5658 10.8803C51.5658 10.8803 51.0425 11.5691 50.7882 11.9009C49.8895 13.0707 50.6844 14.4355 53.618 14.4355H65.1133L67.829 10.8807H51.5658V10.8803Z"
        fill="#D50029"
      />
    </svg>
  );
}
