import React from 'react';

export default function Paypal() {
  return (
    <svg width="74" height="49" viewBox="0 0 74 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2200:7271)">
        <path d="M73.6567 1.18311H0.447266V47.1017H73.6567V1.18311Z" fill="white" />
        <path
          d="M29.631 22.4666C29.4231 23.6301 28.5219 23.6301 27.6206 23.6301H27.1353L27.4819 21.3717C27.4819 21.2349 27.6206 21.1665 27.7592 21.1665H27.9672C28.5911 21.1665 29.1457 21.1665 29.423 21.5086C29.7004 21.7824 29.7004 22.056 29.631 22.4666ZM29.2844 19.3872H25.8875C25.6794 19.3872 25.4714 19.524 25.4022 19.7978L24.0156 28.3519C24.0156 28.4887 24.1542 28.6941 24.2929 28.6941H25.8875C26.0954 28.6941 26.3034 28.5573 26.3726 28.2835L26.7194 25.9567C26.7886 25.7515 26.9273 25.5461 27.2047 25.5461H28.2445C30.4629 25.5461 31.7801 24.5196 32.0575 22.3982C32.1961 21.5086 32.0575 20.7559 31.6416 20.2767C31.2255 19.661 30.3936 19.3872 29.2844 19.3872Z"
          fill="#253B80"
        />
        <path
          d="M37.1181 25.5467C36.9795 26.4362 36.2168 27.0522 35.3156 27.0522C34.8303 27.0522 34.4837 26.9154 34.2064 26.6416C33.9984 26.3678 33.8597 25.9572 33.929 25.5467C34.0677 24.657 34.8303 24.0412 35.7315 24.0412C36.2168 24.0412 36.5634 24.178 36.8408 24.4518C37.0487 24.7255 37.1874 25.0675 37.1181 25.5467ZM39.3365 22.4671H37.7421C37.6034 22.4671 37.4647 22.5355 37.4647 22.6725L37.3955 23.1515L37.2568 23.0147C36.9102 22.5355 36.1475 22.3303 35.3156 22.3303C33.5131 22.3303 31.988 23.6304 31.7106 25.5467C31.5719 26.5048 31.7799 27.3943 32.3346 28.0103C32.8199 28.5576 33.5131 28.8314 34.345 28.8314C35.8009 28.8314 36.5636 27.9419 36.5636 27.9419L36.4942 28.3524C36.4942 28.4892 36.6328 28.6946 36.7715 28.6946H38.2274C38.4353 28.6946 38.6433 28.5576 38.7127 28.284L39.6139 22.8093C39.6139 22.6041 39.5446 22.4671 39.3365 22.4671Z"
          fill="#253B80"
        />
        <path
          d="M47.9369 22.4678H46.3425C46.2038 22.4678 46.0652 22.5362 45.9265 22.673L43.7081 25.9577L42.7375 22.8098C42.6681 22.6046 42.5294 22.4678 42.3215 22.4678H40.7271C40.519 22.4678 40.3803 22.673 40.4497 22.8098L42.2522 28.0107L40.5884 30.3375C40.4497 30.5427 40.5884 30.7481 40.7963 30.7481H42.3909C42.5294 30.7481 42.6681 30.6797 42.8068 30.5427L48.2144 22.8783C48.2837 22.673 48.145 22.4678 47.9369 22.4678Z"
          fill="#253B80"
        />
        <path
          d="M53.6877 22.4666C53.4798 23.6301 52.5785 23.6301 51.6773 23.6301H51.192L51.5386 21.3717C51.5386 21.2349 51.6773 21.1665 51.816 21.1665H52.0239C52.6479 21.1665 53.2025 21.1665 53.4798 21.5086C53.6877 21.7824 53.757 22.056 53.6877 22.4666ZM53.3411 19.3872H49.9441C49.7361 19.3872 49.5282 19.524 49.4588 19.7978L48.0723 28.3519C48.0723 28.4887 48.2108 28.6941 48.3495 28.6941H50.0827C50.2214 28.6941 50.3601 28.5573 50.4294 28.4203L50.8454 26.0253C50.9147 25.8199 51.0533 25.6145 51.3307 25.6145H52.3705C54.5891 25.6145 55.9063 24.588 56.1835 22.4666C56.3222 21.577 56.1835 20.8243 55.7676 20.3451C55.2823 19.661 54.4504 19.3872 53.3411 19.3872Z"
          fill="#179BD7"
        />
        <path
          d="M61.1064 25.5467C60.9677 26.4362 60.2052 27.0522 59.3039 27.0522C58.8186 27.0522 58.472 26.9154 58.1946 26.6416C57.9867 26.3678 57.848 25.9572 57.9174 25.5467C58.0561 24.657 58.8186 24.0412 59.7199 24.0412C60.2052 24.0412 60.5518 24.178 60.829 24.4518C61.1064 24.7255 61.1758 25.0675 61.1064 25.5467ZM63.3942 22.4671H61.7996C61.6611 22.4671 61.5224 22.5355 61.5224 22.6725L61.453 23.1515L61.3143 23.0147C60.9677 22.5355 60.2052 22.3303 59.4425 22.3303C57.6399 22.3303 56.1149 23.6304 55.8374 25.5467C55.6989 26.5048 55.9068 27.3943 56.4615 28.0103C56.9468 28.5576 57.6401 28.8314 58.472 28.8314C59.9279 28.8314 60.6905 27.9419 60.6905 27.9419L60.6211 28.3524C60.6211 28.4892 60.7598 28.6946 60.8984 28.6946H62.3543C62.5623 28.6946 62.7702 28.5576 62.8396 28.284L63.7408 22.8093C63.6715 22.6041 63.5329 22.4671 63.3942 22.4671Z"
          fill="#179BD7"
        />
        <path
          d="M65.2655 19.5941L63.8789 28.285C63.8789 28.422 64.0176 28.6272 64.1563 28.6272H65.5429C65.7508 28.6272 65.9588 28.4904 66.028 28.2166L67.4146 19.6625C67.4146 19.5257 67.276 19.3203 67.1373 19.3203H65.6121C65.4042 19.3887 65.3348 19.4573 65.2655 19.5941Z"
          fill="#179BD7"
        />
        <path
          d="M10.0817 30.2681L10.359 28.6258H9.80434H7.03125L8.90309 16.7184C8.90309 16.65 8.90307 16.65 8.97241 16.65L9.04174 16.5815H13.6867C15.2119 16.5815 16.2518 16.9237 16.8064 17.5396C17.0837 17.8133 17.2224 18.1555 17.2917 18.4977C17.3611 18.8398 17.3611 19.2504 17.2917 19.798V19.8664V20.2085L17.569 20.3453C17.777 20.4823 17.9849 20.6191 18.1236 20.7559C18.3317 21.0297 18.4702 21.3034 18.5396 21.714C18.6089 22.1246 18.6089 22.5352 18.4702 23.0827C18.3317 23.6986 18.193 24.1776 17.9157 24.6566C17.7077 25.0673 17.3611 25.4093 17.0144 25.6147C16.6678 25.8885 16.2518 26.0253 15.8358 26.1622C15.4199 26.299 14.8653 26.3674 14.3106 26.3674H13.964C13.6867 26.3674 13.4787 26.4358 13.2707 26.6412C13.0627 26.778 12.9241 27.0518 12.9241 27.2572V27.394L12.4388 30.2681V30.4051V30.4735C12.4388 30.4735 12.4388 30.4735 12.3695 30.4735H10.0817V30.2681Z"
          fill="#253B80"
        />
        <path
          d="M17.8446 19.7991C17.8446 19.8675 17.8446 20.0044 17.7753 20.0728C17.1514 23.1524 15.0716 24.2473 12.4371 24.2473H11.0506C10.7039 24.2473 10.4266 24.4525 10.4266 24.7947L9.73337 29.1745L9.52539 30.4062C9.52539 30.6116 9.66405 30.8168 9.87202 30.8168H12.2985C12.5758 30.8168 12.8531 30.6116 12.8531 30.3378V30.2008L13.3384 27.3952V27.2583C13.4077 26.9845 13.6157 26.7793 13.893 26.7793H14.2396C16.5967 26.7793 18.3993 25.8212 18.9539 23.1524C19.1618 21.9889 19.0925 21.0994 18.4686 20.415C18.3299 20.1412 18.122 19.936 17.8446 19.7991Z"
          fill="#179BD7"
        />
        <path
          d="M17.2248 19.5257C17.1554 19.5257 17.0167 19.4573 16.9474 19.4573C16.8781 19.4573 16.7394 19.3887 16.6701 19.3887C16.3235 19.3203 15.9075 19.3203 15.4915 19.3203H11.8865C11.8172 19.3203 11.6786 19.3203 11.6092 19.3887C11.4706 19.4573 11.3319 19.5941 11.2626 19.7995L10.5 24.5897V24.7265C10.5693 24.3843 10.8466 24.1791 11.1239 24.1791H12.5105C15.1449 24.1791 17.294 23.0842 17.8486 20.0047C17.8486 19.9363 17.8486 19.7995 17.918 19.7309C17.7793 19.6625 17.5714 19.5941 17.4327 19.5257C17.294 19.5941 17.2248 19.5941 17.2248 19.5257Z"
          fill="#222D65"
        />
        <path
          d="M11.2609 19.7982C11.2609 19.5928 11.3996 19.456 11.6076 19.3876C11.6769 19.3192 11.7462 19.3192 11.8849 19.3192H15.4899C15.9059 19.3192 16.3218 19.3192 16.6685 19.3876C16.8071 19.3876 16.8765 19.456 16.9458 19.456C17.0151 19.456 17.1538 19.5244 17.2232 19.5244C17.2924 19.5244 17.2924 19.5244 17.3617 19.5928C17.5698 19.6614 17.7085 19.7298 17.847 19.7982C18.0551 18.6349 17.847 17.8821 17.2232 17.1977C16.5298 16.445 15.2819 16.1028 13.6874 16.1028H9.04242C8.69578 16.1028 8.41848 16.3081 8.41848 16.6503L6.47731 28.6945C6.40797 28.8998 6.61595 29.1734 6.89326 29.1734H9.7357L10.429 24.657L11.2609 19.7982Z"
          fill="#253B80"
        />
      </g>
      <defs>
        <clipPath id="clip0_2200:7271">
          <rect width="74" height="48" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
