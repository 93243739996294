import React, { useState, useEffect } from 'react';
import Client from 'shopify-buy';

import Footer from '@components/footer/footer';
import Header from '@components/header/header';
import '../styles/main.scss';
import CartContext from '../context/cart-context';
import LanguageContext from '../context/language-context';

const client = Client.buildClient({
  domain: `bwol-ecommerce.myshopify.com`,
  storefrontAccessToken: '242b78c6ec7d8209f83205d519be1248',
});

const Layout = ({ children, ...props }) => {
  // get checkout from local storage if window is not undefined

  const [checkout, setCheckout] = useState(
    JSON.parse(typeof window !== 'undefined' ? localStorage.getItem('checkout') : null)
  );

  const [successfulOrder, setSuccessfulOrder] = useState(null);
  const checkoutId = checkout?.id;
  const [cartData, setCartData] = useState([]);
  const VALID_LOCATIONS = [
    'Georgia',
    'Florida',
    'North Carolina',
    'South Carolina',
    'Kentucky',
    'Alabama',
    'Mississippi',
    'Tennessee',
  ];

  const [geolocationStatus, setGeolocationStatus] = useState(true);
  const [isGeoFetched, setGeoFetched] = useState(false);
  const [contentLanguage, setContentLanguage] = useState('eng');

  const [requestFormBook, setRequestFormBook] = useState('');

  const API_KEY = process.env.GATSBY_GEO_TOKEN || '';

  const geoUrl = `https://geo.webpoint.io?x-webpoint-auth-token=${API_KEY}`;

  useEffect(() => {
    if (isGeoFetched) {
      return;
    }
    fetch(geoUrl)
      .then((res) => res.json())
      .then((response) => {
        const regionName = response.regionName;

        if (VALID_LOCATIONS.find((item) => item.toLowerCase() === regionName.toLowerCase())) {
          setGeolocationStatus(false);
        }
        setGeoFetched(true);
      })
      .catch((data, status) => {
        setGeoFetched(true);
        console.error('Request failed', data, status);
      });
  }, [geolocationStatus]);

  useEffect(() => {
    const data = localStorage.getItem('bookData');

    const languageState = localStorage.getItem('languageState');

    if (languageState === null) {
      localStorage.setItem('languageState', contentLanguage);
    } else {
      setContentLanguage(languageState);
    }

    if (data != null) {
      setCartData(JSON.parse(data));
    } else {
      setCartData([]);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('bookData', JSON.stringify(cartData));
  }, [cartData]);

  React.useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== 'undefined') {
        const fetchedCheckout = await client.checkout.fetch(checkoutId);
        if (fetchedCheckout?.completedAt) {
          localStorage.removeItem('checkout');
          setCheckout(null);
          setSuccessfulOrder(fetchedCheckout);
        } else {
          setCheckout(fetchedCheckout);
          localStorage.setItem('checkout', JSON.stringify(fetchedCheckout));
        }
      }
    };

    getCheckout();
  }, [setCheckout, setSuccessfulOrder, checkoutId]);

  async function getProductById(productId) {
    const product = await client.product.fetch(productId);

    return product;
  }

  const updateLineItem = async ({ variantId, quantity }) => {
    // if no checkout id, create a new checkout
    let newCheckout = checkout || (await client.checkout.create());

    // check to see if this variantId exists in storedCheckout
    const lineItemVariant = newCheckout.lineItems?.find((lineItem) => lineItem.variant.id === variantId);

    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity;

      if (newQuantity) {
        newCheckout = await client.checkout.updateLineItems(newCheckout.id, [
          {
            id: lineItemVariant.id,
            quantity: newQuantity,
          },
        ]);
      } else {
        newCheckout = await client.checkout.removeLineItems(newCheckout.id, [lineItemVariant.id]);
      }
    } else {
      newCheckout = await client.checkout.addLineItems(newCheckout.id, [
        {
          variantId,
          quantity,
        },
      ]);
    }

    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('checkout', JSON.stringify(newCheckout));
    }
  };

  const removeLineItem = async (lineItemId) => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [lineItemId]);

    setCheckout(newCheckout);
  };

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null);
  };

  useEffect(() => {
    localStorage.setItem('languageState', contentLanguage);
  }, [contentLanguage]);

  if (!isGeoFetched) {
    return null;
  }

  return (
    <LanguageContext.Provider value={{ contentLanguage, setContentLanguage }}>
      <CartContext.Provider
        value={{
          cartData,
          geolocationStatus,
          setCartData,
          checkout,
          updateLineItem,
          removeLineItem,
          getProductById,
          successfulOrder,
          dismissSuccessfulOrder,
          requestFormBook,
          setRequestFormBook,
        }}
      >
        <Header />
        <main>{children}</main>
        <Footer />
      </CartContext.Provider>
    </LanguageContext.Provider>
  );
};

export default Layout;
